export default {
  "ABOUT": "การแนะนำ",
  "TOKENOMICS": "โทเคโนมิกส์",
  "STAKING": "การปักหลัก",
  "HOW TO BUY": "วิธีการซื้อ",
  "ROADMAP": "แผนงาน",
  "FAQ": "คำถามที่พบบ่อย",
  "BUY NOW": "ซื้อตอนนี้",
  "ELONXCAT is the real": "ELONXCAT มีจริง",
  "ELONMUSK": "อีลอน มัสก์",
  "SpaceX and X": "สเปซเอ็กซ์และ",
  "meme project": "โครงการมีม",
  "Advanced Web3 Ecosystem": "ระบบนิเวศ Web3 ขั้นสูง",
  "Potential of project": "ศักยภาพของโครงการ",
  "Market cap Target": "มูลค่าหลักทรัพย์ตามราคาตลาดเป้าหมาย",
  "Investors": "นักลงทุน",
  "WHITE PAPER": "สมุดสีขาว",
  "AUDIT": "ขอบคุณ",
  "Presale will start soon. Join the Presale": "เริ่มจำหน่ายล่วงหน้าเร็วๆ นี้ มีส่วนร่วมในการขายล่วงหน้า",
  "TOKEN DISTRIBUTION DATE": "วันที่จำหน่ายโทเค็น",
  "Raised": "จำนวนเงินที่เพิ่มขึ้น",
  "ELONXCAT sold": "ปริมาณการขายของ ELONXCAT",
  "BUY": "ซื้อ",
  "MULTI BRIDGE UTILITY": "ยูทิลิตี้หลายบริดจ์",
  "Total Supply": "อุปทานทั้งหมด",
  "ELONXCAT ACHIEVEMENTS": "ประสิทธิภาพของ ELONXCAT",
  "Ecosystem Percentage": "อัตราส่วนระบบนิเวศ",
  "ELONXCAT QUEST GAME": "เกม ELONXCAT Quest",
  "ELONXCAT GAME PORTAL SOON": "พอร์ทัลเกม ELONXCAT ในเร็วๆ นี้",
  "NFT MARKET PLACE": "ตลาด NFT",
  "Unique collections": "คอลเลกชันที่ไม่ซ้ำใคร",
  "Launch at the same time as the project": "ออกมาพร้อมกับโครงการ",
  "NFT Box and Airdrop": "กล่อง NFT และแอร์ดรอป",
  "DISCOVER NFT": "สำรวจ NFT",
  "AI-Powerde Content": "เนื้อหาที่ใช้ AI",
  "AI Image Generator": "เครื่องกำเนิดภาพ AI",
  "AI Chat Bot": "แชทบอท AI",
  "AI Code": "รหัสเอไอ",
  "COIN SWAP": "การแลกเปลี่ยนเหรียญ",
  "ELONXCAT ALLOCAT STASTISTICS": "สถิติการจัดสรร ELONXCAT",
  "ELONXCAT STAKING SERVICE IS COMING SOON": "บริการปักหลัก ELONXCAT เร็ว ๆ นี้",
  "WELCOME TO ELONXCAT STAKING": "ยินดีต้อนรับสู่การปักหลัก ELONXCAT",
  "WITHDRAW STAKED TOKENS": "ถอนโทเค็นที่เดิมพันไว้",
  "STAKED BALANCE": "การวางเดิมพันสมดุล",
  "YOUR STAKEABLE": "ยอดคงเหลือสำหรับการเดิมพัน",
  "TOTAL STAKED": "การวางเดิมพันทั้งหมด",
  "ESTIMATED REWARDS": "ผลตอบแทนที่คาดหวัง",
  "Rewards rate is dynamic": "อัตราส่วนรางวัลเป็นแบบไดนามิก",
  "Monthly": "รายเดือน",
  "Daily": "เหลือบมอง",
  "CURRENT REWARDS": "รางวัลปัจจุบัน",
  "TOTAL REWARDS": "รางวัลทั้งหมด",
  "CLAIM REWARDS": "การเรียกร้องค่าชดเชย",
  "TOTAL SUPPLY": "อุปทานทั้งหมด",
  "OUR PARTNERS and BACKERS": "พันธมิตรและผู้สนับสนุนของเรา",
  "ELONXCAT TEAM": "ทีมงาน ELONXCAT",
  "Founder": "ผู้ก่อตั้ง",
  "Creative Director": "ผู้อำนวยการฝ่ายสร้างสรรค์",
  "Community manger": "ผู้จัดการชุมชน",
  "CONNECT YOUR WALLET": "การเชื่อมต่อกระเป๋าเงิน",
  "ENTER PRESALE": "การเข้าร่วมก่อนการขาย",
  "CLAIM ELONXCAT": "การเรียกเก็บเงิน ELONXCAT",
  "ROAD MAP": "แผนงาน",
  "DEVELOPMENT": "การพัฒนา",
  "MEME DOMINATION": "การบริจาคมีม",
  "FREQUENTLY ASKED QUESTIONS": "คำถามที่พบบ่อย",
  "What is a presale": "การขายล่วงหน้าคืออะไร?",
  "What is ELONXCAT": "ELONXCAT คืออะไร?",
  "How do I participate in the presale": "ฉันจะเข้าร่วมพรีเซลได้อย่างไร?",
  "When do I receive my presale tokens": "ฉันจะได้รับโทเค็นการขายล่วงหน้าเมื่อใด?",
  "What is ELONXCAT staking": "การเดิมพัน ELONXCAT คืออะไร?",
  "How do I stake my tokens": "ฉันจะเดิมพันโทเค็นของฉันได้อย่างไร?",
  "I staked my tokens during presale": "ฉันเดิมพันโทเค็นระหว่างการขายล่วงหน้า ฉันจะรับโทเค็นได้เมื่อใด",
  "STAY CONNECTED": "เชื่อมต่ออยู่เสมอ",
  "PRIVACY POLICY": "นโยบายความเป็นส่วนตัว",
  "HOW TO BUY": "วิธีการซื้อ",
  "TERMS and CONDITIONS": "เงื่อนไขการใช้งาน",
  "PRESALE ALLOCATION": "การจัดสรรก่อนการขาย",
  "AIRDROP": "แอร์ดรอป",
  "PROJECTFUNDS": "เงินทุนโครงการ",
  "LIQUIDITY": "สภาพคล่อง",
  "MARKETING": "การตลาด",
  "EXCHANGES": "แลกเปลี่ยน",
  "ECOSYSTEM FUNDS": "กองทุนระบบนิเวศ",
  "EXPANSION": "การขยายตัว",

  "texts": {
    "txt_1": "เทคโนโลยีของเราเองที่เรียกว่า X รองรับมัลติเชนนี้ X รองรับฟังก์ชันการโยกย้ายลูกโซ่ ช่วยให้ผู้ถือ ELONXCAT สามารถเคลื่อนย้ายได้อย่างอิสระผ่านลูกโซ่สกุลเงินดิจิทัลชั้นนำ",
    "txt_2": "ELONXCAT เป็น meme token ที่สร้างขึ้นบนเครือข่าย multichain ซึ่งครอบคลุมเครือข่าย BNB, Ethereum, Polygon, Solana และ Tron",
    "txt_3": "ELONXCAT มุ่งมั่นที่จะรวมชุมชน meme ของ cryptocurrency และกลายเป็นผู้บุกเบิกในด้านสาธารณูปโภคแบบหลายสายโซ่",
    "txt_4": "เป้าหมายของเราคือการทำให้ชื่อของเราโดดเด่นในทุก DEX ทุก CEX และทุกที่",
    "txt_5": "การใช้ประโยชน์",
    "txt_6": "X จะยังคงขยาย multichains เหล่านี้ต่อไปในอนาคต",
  },
  "PRESALE TIERS": "ขั้นตอนการขายล่วงหน้า",

  "tiers": {
    "tier_txt_1": "ราคาพรีเซลเริ่มต้นอยู่ที่ 0.0000009 ดอลลาร์สหรัฐฯ ต่อที่นั่ง ซึ่งจะเปลี่ยนเป็น Tier2 หลังจาก 20 วัน",
    "tier_txt_2": "ราคาของชั้นนี้ตั้งไว้ที่ 0.000001 ดอลลาร์สหรัฐฯ จากการพรีเซล ซึ่งจะเปลี่ยนเป็น Tier3 หลังจาก 20 วัน",
    "tier_txt_3": "ราคาของชั้นนี้ตั้งไว้ที่ 0.000005 ดอลลาร์สหรัฐฯ จากการพรีเซล ซึ่งจะเปลี่ยนเป็นราคาสุดท้ายหลังจาก 20 วัน",
    "tier_txt_4": "ราคาพรีเซลของชั้นนี้คือ 0.000009 ดอลลาร์สหรัฐฯ ซึ่งจะสิ้นสุดการพรีเซล ELONXCAT ด้วยราคา นี้",
    "tier_txt_5": "ELONXCAT มุ่งหวังที่จะเป็น DEX ที่ดีที่สุดใน BSC ด้วยฟีเจอร์ต่างๆ เช่น การแลกเปลี่ยนโทเค็น แหล่งรวมสภาพคล่อง และการทำฟาร์มผลตอบแทน",
    "tier_txt_6": "ELONXCAT Quest ผสมผสานการเล่นเกมและสกุลเงินดิจิทัลเข้าด้วยกัน ทำให้คุณได้รับโอกาสในการรับโทเค็น Lope ผ่านภารกิจและปริศนาในโลกบล็อกเชน",
    "tier_txt_7": "โครงการ ELONXCAT NFT ผสมผสานศิลปะดิจิทัลเข้ากับ Memecoin ของ ELONXCAT เพื่อมอบของสะสมที่มีเอกลักษณ์และมีประโยชน์มากมาย เพื่อเพิ่มการมีส่วนร่วมของชุมชน",
    "tier_txt_8": "ELONXCAT ผสมผสานการเงินและความคิดสร้างสรรค์ไว้ในแพลตฟอร์มที่ใช้งานง่ายโดยการผสานรวม AI สำหรับการแชท เคล็ดลับการซื้อขาย การสร้างมีม และอื่นๆ",
    "tier_txt_9": "ELONXCAT Quest เป็นเกมบล็อกเชนภายในระบบนิเวศของ ELONXCAT Coin ที่ผสมผสานการเล่นเกมและสกุลเงินดิจิทัลในโลกดิจิทัลที่ได้รับแรงบันดาลใจจากมาสคอตแมว ประกอบไปด้วยภารกิจ ปริศนา และการท้าทาย PVP/PVE ผู้เล่นสามารถรับโทเค็น Lope ผ่านการผจญภัย กลยุทธ์ และการโต้ตอบทางสังคม เกมดังกล่าวมีจุดมุ่งหมายเพื่อแนะนำผู้ใช้ให้รู้จักกับบล็อกเชนและสร้างชุมชนผ่านคุณสมบัติผู้เล่นหลายคน",
    "tier_txt_10": "ELONXCAT Coin NFT Marketplace มีคอลเลกชันที่เป็นเอกลักษณ์พร้อมคุณสมบัติพิเศษและไม่เหมือนใคร",
    "tier_txt_11": "ELONXCAT Coin NFT จะถูกจดทะเบียนพร้อมกับโครงการในตลาดโลกทั้งหมด รวมถึงในตลาดของโครงการเอง",
    "tier_txt_12": "ตลาดมีโปรแกรม Airdrop ขนาดใหญ่ที่จะเปิดใช้งานในช่วง NFT Box และการขายโทเค็นล่วงหน้า",
    "tier_txt_13": "สร้างบล็อกที่ปรับให้เหมาะสมกับ SEO อีเมลการขาย และอื่นๆ... ประหยัดเวลาและรับผลลัพธ์ที่ยอดเยี่ยม สุดท้ายนี้ ก็มีเครื่องมือเขียนที่ใช้งานได้จริง",
    "tier_txt_14": "จินตนาการถึงสิ่งที่คุณฝันถึง สร้างภาพพร้อมข้อความ สร้างภาพที่น่าทึ่งทันทีสำหรับนวัตกรรมทางศิลปะของคุณด้วยโปรแกรมสร้างภาพ AI ของเรา",
    "tier_txt_15": "เพียงเลือกผู้ช่วยของคุณและแชทกับแชทบอทที่เป็นมิตรเพื่อสร้างไอเดีย ปรับปรุงเนื้อหาของคุณ และบางครั้งก็สร้างเสียงหัวเราะได้ด้วย",
    "tier_txt_16": "พร้อมที่จะเขียนโค้ดด้วยความเร็วสูงแล้วหรือยัง? เขียนโค้ดได้อย่างง่ายดายด้วย AI Code Generator: มอบโซลูชันทันทีให้กับความต้องการในการเขียนโปรแกรมของคุณ",
    "tier_txt_17": "ELONXCAT Swap มีเป้าหมายที่จะเป็นแพลตฟอร์มการแลกเปลี่ยนแบบกระจายอำนาจ (DEX) ชั้นนำบน Binance Smart Chain (BSC)",
    "tier_txt_18": "ELONXCAT Swap ให้การแลกเปลี่ยนโทเค็นที่ราบรื่น จัดให้มีกลุ่มสภาพคล่อง และแนะนำคุณสมบัติที่เป็นนวัตกรรม เช่น การทำฟาร์มผลผลิตและการวางเดิมพันเพื่อเพิ่มสภาพคล่อง รับประกันการซื้อขายที่ยุติธรรม และส่งเสริมการเติบโตโดยรวมของชุมชน ELONXCAT",
    "tier_txt_19": "การแจกจ่ายรางวัลโทเค็น ELONXCAT จะดำเนินการภายในสองปีในอัตรา 708.72 $ELONXCAT ต่อบล็อก Ethereum และสามารถขอรับได้เมื่อเปิดใช้งานการอ้างสิทธิ์",
    "tier_txt_20": "เชื่อมต่อกระเป๋าเงินของคุณในไม่กี่วินาทีโดยใช้ Metamask หรือ Trust Wallet",
    "tier_txt_21": "ตัดสินใจว่าคุณต้องการซื้อ $ELONXCAT เป็นจำนวนเท่าใด   คุณสามารถซื้อด้วย USDT พร้อมกับ BNB, ETH และ USDT",
    "tier_txt_22": "เมื่อการขายล่วงหน้าสิ้นสุดลง คุณสามารถรับโทเค็น $ELONXCAT ของคุณผ่านกระเป๋าเงินและเครือข่ายเดียวกันกับที่คุณใช้ซื้อ",
    "tier_txt_23": "การกำเนิดของ ELONMUSK &",
    "tier_txt_24": "การสร้างชุมชน  การสร้างโทเค็น  ช่องทางโซเชียล  การเกิด",
    "tier_txt_25": "KYC & Audit  Marketing Pump  เริ่มต้นการขายล่วงหน้า",
    "tier_txt_26": "เริ่มการตลาด  CoinMarketCap   สมัคร  สมัคร CoinGecko  อัปเดต ElonXCAT NFT  การพัฒนาเกม ElonXCAT  อัปเดตการวางเดิมพัน",
    "tier_txt_27": "รายการ DEX แรก   อัปเดต DEXTools   อัปเดต ElonXCAT AI  การเผาไหม้อัตโนมัติ",
    "tier_txt_28": "การตลาดที่กำลังดำเนินอยู่  สิ่งจูงใจจากชุมชน  ความร่วมมือกับแพลตฟอร์มอื่น ๆ   จำนวนเป้าหมายของผู้ถือครอง: 1 ล้านคนขึ้นไป",
    "tier_txt_29": "การขายล่วงหน้าเป็นโอกาสพิเศษสำหรับนักลงทุนในการซื้อโทเค็นหรือสกุลเงินดิจิทัลใหม่ก่อนเปิดตัวอย่างเป็นทางการ ขั้นตอนการเข้าถึงล่วงหน้านี้ช่วยให้เราสามารถใช้ประโยชน์จากศักยภาพของโครงการได้ตั้งแต่เริ่มต้น",
    "tier_txt_30": "ELONXCAT เป็นเหรียญมีมแบบหลายสายโซ่ขั้นสูงอย่างแท้จริง และแท้จริงแล้วคือ ELONMUSK & ระบบนิเวศ Web3 ขั้นสูง: การปักหลัก | เด็กซ์ | เอไอ | เกม | ตลาดเอ็นเอฟที",
    "tier_txt_31": "คุณสามารถเข้าร่วมการขายล่วงหน้าบนเครือข่าย BNB ได้ เชื่อมต่อ Best Wallet หรือกระเป๋าเงินอื่นเข้ากับวิดเจ็ตการขายล่วงหน้าด้านบนและจองโทเค็น $ELONXCAT ด้วย BNB, ETH หรือ USDT",
    "tier_txt_32": "เมื่อการซื้อล่วงหน้าของคุณเสร็จสมบูรณ์ คุณสามารถใช้กระเป๋าเงินเดียวกันเพื่อรับโทเค็นของคุณก่อนรายการ DEX แรก วันที่เรียกเก็บเงินและรายการจะมีการประกาศในบัญชีโซเชียลมีเดียของเรา",
    "tier_txt_33": "การปักหลักคือการส่งโทเค็นไปยังสัญญาอัจฉริยะเพื่อล็อคโทเค็นไว้ในช่วงระยะเวลาหนึ่ง สิ่งนี้ช่วยให้คุณได้รับโทเค็น $ELONXCAT มากขึ้นเมื่อเวลาผ่านไปในอัตราแบบไดนามิก การวางเดิมพันของ ELONXCAT จะมีให้บริการบนเครือข่าย BNB เร็วๆ นี้ สำหรับข้อมูลเพิ่มเติม คลิกที่ 'การปักหลัก' ในแถบนำทาง",
    "tier_txt_34": "หากต้องการเดิมพัน $ELONXCAT โปรดไปที่นี่: https://ELONXCAT/en/stake การวางเดิมพันสามารถทำได้เมื่อ $ELONXCAT ซื้อบน BNB เท่านั้น คุณจะต้องมี $BNB ในกระเป๋าเงินของคุณเพื่อดำเนินการยกเลิกการเดิมพันและเรียกร้องรางวัลจากการปักหลัก",
    "tier_txt_35": "หากคุณได้เดิมพัน $ELONXCAT คุณต้องรอระยะเวลาให้สิทธิ์อย่างน้อย 14 วันก่อนที่จะรับสิทธิ์ การปักหลักสามารถทำได้ด้วยการซื้อ $BNB บน Ethereum เท่านั้น คุณจะต้องมี $BNB ในกระเป๋าเงินของคุณเพื่อดำเนินการยกเลิกการเดิมพันและเรียกร้องรางวัลจากการปักหลัก",
    "tier_txt_36": "การปักหลักคือการส่งโทเค็นไปยังสัญญาอัจฉริยะเพื่อล็อคโทเค็นไว้ในช่วงระยะเวลาหนึ่ง สิ่งนี้ช่วยให้คุณได้รับโทเค็น $ELONXCAT มากขึ้นเมื่อเวลาผ่านไปในอัตราแบบไดนามิก การวางเดิมพันของ ELONXCAT จะมีให้บริการบนเครือข่าย BNB เร็วๆ นี้ สำหรับข้อมูลเพิ่มเติม คลิกที่ 'การปักหลัก' ในแถบนำทาง",
    "tier_txt_37": "หากต้องการเดิมพัน $ELONXCAT โปรดไปที่นี่: https://ELONXCAT/en/stake การวางเดิมพันสามารถทำได้เมื่อ $ELONXCAT ซื้อบน BNB เท่านั้น คุณจะต้องมี $BNB ในกระเป๋าเงินของคุณเพื่อดำเนินการยกเลิกการเดิมพันและเรียกร้องรางวัลจากการปักหลัก",
    "tier_txt_38": "หากคุณได้เดิมพัน $ELONXCAT คุณต้องรอระยะเวลาให้สิทธิ์อย่างน้อย 14 วันก่อนที่จะรับสิทธิ์ การปักหลักสามารถทำได้ด้วยการซื้อ $BNB บน Ethereum เท่านั้น คุณจะต้องมี $BNB ในกระเป๋าเงินของคุณเพื่อดำเนินการยกเลิกการเดิมพันและเรียกร้องรางวัลจากการปักหลัก",
    "tier_txt_39": "สำนักงาน ELONXCAT ปานามา: ชั้น 43rd ธุรกิจโอเชียเนีย Plaza, Tower 2000, Punta Colon Street, Urbanisación Punta  Pacifica, ปานามาซิตี้, ปานามา",
    "tier_txt_40": "ข้อจำกัดความรับผิดชอบ: ELONXCAT เป็นเหรียญมีม มูลค่าของมันขึ้นอยู่กับเมืองหลวงทางวัฒนธรรมสากลของมีม ELONXCAT เท่านั้น  มูลค่าของสกุลเงินดิจิทัลสามารถขึ้นหรือลงได้ ลงทุนอย่างระมัดระวัง",
  },
  "send_success_message": "การชำระเงินเสร็จสมบูรณ์เรียบร้อยแล้ว โทเค็น ElonXCat จะถูกส่งไปยังที่อยู่การชำระเงินของคุณภายใน 5 นาที"
}