export default {
  "ABOUT": "はじめに",
  "TOKENOMICS": "トークノミックス",
  "STAKING": "ステーキング",
  "HOW TO BUY": "購入方法",
  "ROADMAP": "ロードマップ",
  "FAQ": "よくある質問",
  "BUY NOW": "今買う",
  "ELONXCAT is the real": "ELONXCATは本物です",
  "ELONMUSK": "エロン・ムスク",
  "SpaceX and X": "スペースXとX",
  "meme project": "ミームプロジェクト",
  "Advanced Web3 Ecosystem": "高度なWeb3エコシステム",
  "Potential of project": "プロジェクトの可能性",
  "Market cap Target": "目標時価総額",
  "Investors": "投資家",
  "WHITE PAPER": "ホワイトペーパー",
  "AUDIT": "ありがとう",
  "Presale will start soon. Join the Presale": "事前販売が間もなく始まります。事前販売に参加",
  "TOKEN DISTRIBUTION DATE": "トークン配布日",
  "Raised": "募金額",
  "ELONXCAT sold": "ELONXCAT販売量",
  "BUY": "購入",
  "MULTI BRIDGE UTILITY": "マルチブリッジユーティリティ",
  "Total Supply": "総供給量",
  "ELONXCAT ACHIEVEMENTS": "ELONXCATのパフォーマンス",
  "Ecosystem Percentage": "生態系の割合",
  "ELONXCAT QUEST GAME": "ELONXCATクエストゲーム",
  "ELONXCAT GAME PORTAL SOON": "まもなくELONXCATゲームポータルをリリース",
  "NFT MARKET PLACE": "NFTマーケットプレイス",
  "Unique collections": "ユニークなコレクション",
  "Launch at the same time as the project": "プロジェクトと同時にリリース",
  "NFT Box and Airdrop": "NFTボックスとエアドロップ",
  "DISCOVER NFT": "NFTナビゲーション",
  "AI-Powerde Content": "AIベースのコンテンツ",
  "ELONXCAT STAKING SERVICE IS COMING SOON": "ELONXCAT ステーキングサービス まもなく発売予定",
  "WELCOME TO ELONXCAT STAKING": "ELONXCAT ステーキングへようこそ",
  "WITHDRAW STAKED TOKENS": "ステーキングされたトークンの引き出し",
  "STAKED BALANCE": "ステーキング残高",
  "YOUR STAKEABLE": "ステーキング可能な残高",
  "TOTAL STAKED": "総ステーキング",
  "ESTIMATED REWARDS": "予想報酬",
  "Rewards rate is dynamic": "報酬率は動的",
  "Monthly": "毎月",
  "Daily": "毎日",
  "CURRENT REWARDS": "現在の報酬",
  "TOTAL REWARDS": "総報酬",
  "CLAIM REWARDS": "報酬請求",
  "TOTAL SUPPLY": "総供給量",
  "OUR PARTNERS and BACKERS": "私たちのパートナーとスポンサー",
  "ELONXCAT TEAM": "ELONXCATチーム",
  "Founder": "創設者",
  "Creative Director": "クリエイティブディレクター",
  "Community manger": "コミュニティマネージャ",
  "CONNECT YOUR WALLET": "ウォレット接続",
  "ENTER PRESALE": "事前販売参加",
  "CLAIM ELONXCAT": "ELONXCAT請求",
  "ROAD MAP": "ロードマップ",
  "DEVELOPMENT": "開発",
  "MEME DOMINATION": "ミームドネーション",
  "FREQUENTLY ASKED QUESTIONS": "よくある質問",
  "What is a presale": "事前販売とは何ですか？",
  "What is ELONXCAT": "ELONXCATとは何ですか？",
  "How do I participate in the presale": "事前販売にどのように参加しますか？",
  "When do I receive my presale tokens": "事前販売トークンはいつ受け取れますか？",
  "What is ELONXCAT staking": "ELONXCATステーキングとは何ですか？",
  "How do I stake my tokens": "トークンをどのようにステーキングしますか？",
  "I staked my tokens during presale": "事前販売中にトークンをステーキングしましたが、いつ請求できますか？",
  "STAY CONNECTED": "接続を維持",
  "PRIVACY POLICY": "個人情報保護方針",
  "TERMS and CONDITIONS": "利用規約",
  "PRESALE ALLOCATION": "事前販売割当",
  "AIRDROP": "エアドロップ",
  "PROJECTFUNDS": "プロジェクト資金",
  "LIQUIDITY": "流動性",
  "MARKETING": "マーケティング",
  "EXCHANGES": "取引所",
  "ECOSYSTEM FUNDS": "生態系資金",
  "EXPANSION": "拡張",

  "texts": {
    "txt_1": "Xという独自の技術がこのマルチチェーンをサポートしています。 Xはチェーン移行機能をサポートし、ELONXCAT保有者が最高の暗号通貨チェーンを自由に移動できるようにします。",
    "txt_2": "ELONXCATは、BNBチェーン、イーサリアム、ポリゴン、ソラナ、トロンを組み合わせたマルチチェーンネットワークに構築されたミームトークンです。",
    "txt_3": "ELONXCATは暗号通貨ミームコミュニティを統合し、マルチチェーンユーティリティの先駆者になるよう努めています。",
    "txt_4": "私たちの目標は、すべてのDEX、すべてのCEX、そしてどこでも私たちの名前を輝かせることです。",
    "txt_5": "Xの優れたブリッジ技術を活用し、ビットコインを除く最も活発な5つのブロックチェーンで、$ELONXCATは数回のクリックでガスコストの心配なしにスムーズにマルチチェーン化されます。",
    "txt_6": "Xは今後もこれらのマルチチェーンを拡張し続けます。",
  },
  "PRESALE TIERS": "事前販売段階",

  "tiers": {
    "tier_txt_1": "初期のプレセール価格は座席あたり0.0000009ドルで、20日後にTier2に変わります。",
    "tier_txt_2": "このティアの価格はプレセールから0.000001ドルに設定され、20日後にTier3に変わります。",
    "tier_txt_3": "このティアの価格はプレセールから0.000005ドルに設定され、20日後に最終価格に変わります。",
    "tier_txt_4": "このティアのプレセール価格は0.000009ドルで、この価格でELONXCATプレセールが終了します。",
    "tier_txt_5": "ELONXCATは、トークンスワップ、流動性プール、収益農業などの機能により、BSCの最高のDEXになることを目指しています。",
    "tier_txt_6": "ELONXCATクエストはゲームと暗号通貨を組み合わせてブロックチェーンの世界でクエストとパズルを通じてLopeトークンを獲得する機会を提供します。",
    "tier_txt_7": "ELONXCAT NFTプロジェクトは、デジタルアートをELONXCATミームコインと組み合わせて、ユニークでユーティリティ豊富なコレクションを提供し、コミュニティへの参加を強化します。",
    "tier_txt_8": "ELONXCATは、チャット、取引のヒント、ミーム作成などのためのAIを統合し、金融と創造性をユーザーフレンドリーなプラットフォームで組み合わせます。",
    "tier_txt_9": "ELONXCATクエストはELONXCATコインエコシステム内のブロックチェーンゲームで、猫のマスコットに触発されたデジタル世界でゲームと暗号通貨を組み合わせます。クエスト、パズル、PVP/PVEチャレンジの課題を提供し、プレイヤーは冒険、戦略、社会的相互作用を通じてLopeトークンを獲得することができます。このゲームは、ユーザーにブロックチェーンを紹介し、マルチプレイ機能を通じてコミュニティを構築することを目指しています。",
    "tier_txt_10": "ELONXCATコインNFTマーケットプレイスは、特別でユニークな機能を持つユニークなコレクションを持っています。",
    "tier_txt_11": "ELONXCATコインNFTは、プロジェクトとともに、すべてのグローバル市場だけでなく、プロジェクト自体市場でも上場される予定です。",
    "tier_txt_12": "このマーケットには、NFTボックスとトークンの事前販売中にアクティブになる大規模なエアドロッププログラムが含まれています。",
    "tier_txt_13": "SEO最適化されたブログ、販売メールなどを書いてください...時間を節約しながら優れた結果を得ることができます。ついに真に使用できる作成ツールが出ました。",
    "tier_txt_14": "あなたが夢を見ることを可視化してください。テキストで画像を作成します。 AIイメージジェネレータを使用して、芸術的革新のための素晴らしいイメージをすぐに作成します。",
    "tier_txt_15": "単にアシスタントを選択し、フレンドリーなチャットボットと会話してアイデアを作成し、コンテンツを向上させ、時には笑いを与えることもできます。",
    "tier_txt_16": "光の速度でコードを書く準備ができましたか？ AIコードジェネレータで簡単にコーディング：プログラミングのニーズに即座にソリューションを提供します。",
    "tier_txt_17": "ELONXCAT Swapは、Binance Smart Chain（BSC）上の主要な脱中央取引所（DEX）プラットフォームを目指しています。",
    "tier_txt_18": "ELONXCAT Swapは円滑なトークンスワップを提供し、流動性プールを提供し、収益農業やステーキングなどの革新的な機能を導入し、流動性を強化し、公正な取引を保証し、ELONXCATコミュニティの全体的な成長を促進します。",
    "tier_txt_19": "ELONXCATトークン報酬の配布は、イーサリアムブロックあたり708.72 $ ELONXCATの割合で2年間にわたって行われます。",
    "tier_txt_20": "MetamaskまたはTrust Walletを使用して数秒で財布を接続します。",
    "tier_txt_21": "購入する$ELONXCAT数量を決定します。  BNB、ETH、USDT で USDT で購入できます。",
    "tier_txt_22": "事前販売が終了したら、購入に使用したのと同じ財布とチェーンを通じて$ ELONXCATトークンを請求できます。",
    "tier_txt_23": "ELONMUSK&Xの誕生",
    "tier_txt_24": "コミュニティの構築 トークンの生成 ソーシャルチャンネル 誕生",
    "tier_txt_25": "KYCと監査 マーケティングポンプ 事前販売開始",
    "tier_txt_26": "マーケティング開始 CoinMarketCap  アプリケーション CoinGecko アプリケーション ElonXCAT NFTアップデート ElonXCATゲーム開発 ステーキングアップデート",
    "tier_txt_27": "最初のDEX上場 DEXToolsアップデート ElonXCAT AIアップデート 自動焼却",
    "tier_txt_28": "継続的なマーケティング コミュニティインセンティブ 他のプラットフォームとのパートナーシップ 保有者数100万+目標",
    "tier_txt_29": "事前販売は、投資家が正式リリース前に新しいトークンまたは暗号通貨を購入する特別な機会を提供します。この初期アクセス段階では、プロジェクトの可能性を初期段階から投資することができます。",
    "tier_txt_30": "ELONXCATは本当に進歩したマルチチェーンミームコインであり、実際にELONMUSK＆Xミームプロジェクトです。高度なWeb3エコシステム：ステーキ| DEX | AI |ゲーム| NFTマーケット。",
    "tier_txt_31": "BNBチェーンで事前販売に参加できます。上部の事前販売ウィジェットにBest Walletまたは他のウォレットをリンクし、BNB、ETH、USDTで$ ELONXCATトークンを予約してください。",
    "tier_txt_32": "事前販売購入が完了したら、最初のDEX上場前に同じウォレットを使用してトークンを請求できます。請求および上場日は、当社のソーシャルメディアアカウントで発表される予定です。",
    "tier_txt_33": "ステーキングは、トークンをスマートコントラクトに送信して一定期間ロックする行為です。これにより、動的な割合で時間の経過とともにより多くの$ ELONXCATトークンを取得できます。 ELONXCATステーキングはすぐにBNBネットワークで利用可能になります。詳細については、ナビゲーションバーの「ステーキ」をクリックしてください。",
    "tier_txt_34": "$ELONXCATをステーキングするには、こちらをご覧ください。ステーキング解除とステーキング報酬請求を処理するには、ウォレットに$ BNBが必要です。",
    "tier_txt_35": "$ELONXCATをステーキングした場合は、請求する前に最低14日のベスト期間を待つ必要があります。ステーキングはイーサリアムで購入した$BNBのみ可能です。ステーキング解除とステーキング報酬請求を処理するには、ウォレットに$ BNBが必要です。",
    "tier_txt_36": "ステーキングは、トークンをスマートコントラクトに送信し、一定期間ロックする行為です。これにより、動的な割合で時間の経過とともにより多くの$ ELONXCATトークンを取得できます。 ELONXCATステーキングはすぐにBNBネットワークで利用可能になります。詳細については、ナビゲーションバーの「ステーキ」をクリックしてください。",
    "tier_txt_37": "$ELONXCATをステーキングするには、こちらをご覧ください。ステーキング解除とステーキング報酬請求を処理するには、ウォレットに$ BNBが必要です。",
    "tier_txt_38": "$ELONXCATをステーキングした場合は、請求する前に最低14日のベスト期間を待つ必要があります。ステーキングはイーサリアムで購入した$BNBのみ可能です。ステーキング解除とステーキング報酬請求を処理するには、ウォレットに$ BNBが必要です。",
    "tier_txt_39": "ELONXCATオフィスパナマ：43階、オセアニアビジネス プラザ、タワー2000、プンタコロンストリート、ウルヴァニサシオンプンタ パシフィカ、パナマシティ、パナマ",
    "tier_txt_40": "免責事項：ELONXCATはミームコインであり、その価値はELONXCATミームの普遍的な文化資本にのみ基づいています。暗号通貨の価値は上がるかもしれません。慎重に投資してください。",
  },
  "send_success_message": "お支払いが正常になりました。 5分以内にElonXCatトークンが支払ったアドレスに送信されます。"
}