import React, { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import './EmblaYoutubeCrousel.css';

const EmblaYoutubeCrousel = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [slidesInView, setSlidesInView] = useState(0);

  const videos = [
    { link: 'https://www.youtube.com/embed/jmDLJKHR84I?si=xHM9q5bsNHvQd3XR', id: 'jmDLJKHR84I' },
    { link: 'https://www.youtube.com/embed/fz7-ODr9H2Q?si=9x8mmqcxKBFKZ6pQ', id: 'fz7-ODr9H2Q' },
    { link: 'https://www.youtube.com/embed/o8FuXxopRUY?si=obm4PXiTenfNr_Tj', id: 'o8FuXxopRUY' },
    { link: 'https://www.youtube.com/embed/Gu0ogAL7kJs?si=v7k4Okk8z-c4N-mu', id: 'Gu0ogAL7kJs' },
    { link: 'https://www.youtube.com/embed/GRdzfFJmR74?si=M73okjAK0IZ3N6O6', id: 'GRdzfFJmR74' },
  ];

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setSlidesInView(emblaApi.scrollSnapList().length);
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on('select', onSelect);
    onSelect();
  }, [emblaApi, onSelect]);

  return (
    <div className="carousel">
      <button className="carousel__button carousel__button--prev" onClick={scrollPrev}>
        ◀
      </button>
      <div className="carousel__viewport" ref={emblaRef}>
        <div className="carousel__container">
          {videos.map((vid) => (
            <div key={vid.id} className="carousel__slide">
              <iframe
                width="100%"
                height="315"
                src={vid.link}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ))}
        </div>
      </div>
      <button className="carousel__button carousel__button--next" onClick={scrollNext}>
        ▶
      </button>

      {/* Dots Navigation */}
      <div className="carousel__dots">
        {Array.from({ length: slidesInView }).map((_, index) => (
          <button
            key={index}
            className={`carousel__dot ${index === selectedIndex ? 'carousel__dot--active' : ''}`}
            onClick={() => emblaApi && emblaApi.scrollTo(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default EmblaYoutubeCrousel;
