export default {
  "ABOUT": "introduction",
  "TOKENOMICS": "Tokénomique",
  "STAKING": "jalonnement",
  "HOW TO BUY": "Comment acheter",
  "ROADMAP": "feuille de route",
  "FAQ": "Foire aux questions",
  "BUY NOW": "acheter maintenant",
  "ELONXCAT is the real": "ELONXCAT est réel",
  "ELONMUSK": "Elon Musk",
  "SpaceX and X": "SpaceX et",
  "meme project": "projet de mème",
  "Advanced Web3 Ecosystem": "Écosystème Web3 avancé",
  "Potential of project": "Potentiel du projet",
  "Market cap Target": "capitalisation boursière cible",
  "Investors": "investisseurs",
  "WHITE PAPER": "livre blanc",
  "AUDIT": "merci",
  "Presale will start soon. Join the Presale": "Les préventes commencent bientôt. Participez à la prévente",
  "TOKEN DISTRIBUTION DATE": "Date de distribution des jetons",
  "Raised": "Montant récolté",
  "ELONXCAT sold": "Volume des ventes de ELONXCAT",
  "BUY": "achat",
  "MULTI BRIDGE UTILITY": "Utilitaire de ponts multiples",
  "Total Supply": "approvisionnement total",
  "ELONXCAT ACHIEVEMENTS": "Performances d`ELONXCAT",
  "Ecosystem Percentage": "ratio d`écosystème",
  "ELONXCAT QUEST GAME": "Jeu de quête ELONXCAT",
  "ELONXCAT GAME PORTAL SOON": "Le portail de jeux ELONXCAT sera bientôt disponible",
  "NFT MARKET PLACE": "Marché NFT",
  "Unique collections": "collection unique",
  "Launch at the same time as the project": "Sorti en même temps que le projet",
  "NFT Box and Airdrop": "Boîte NFT et airdrop",
  "DISCOVER NFT": "Explorez les NFT",
  "AI-Powerde Content": "Contenu basé sur l`IA",
  "AI Image Generator": "Générateur d`images IA",
  "AI Chat Bot": "Chatbot IA",
  "AI Code": "Code IA",
  "COIN SWAP": "Échange de pièces",
  "ELONXCAT ALLOCAT STASTISTICS": "Statistiques d`attribution d`ELONXCAT",
  "ELONXCAT STAKING SERVICE IS COMING SOON": "Le service de jalonnement ELONXCAT sera bientôt disponible",
  "WELCOME TO ELONXCAT STAKING": "Bienvenue sur le jalonnement ELONXCAT",
  "WITHDRAW STAKED TOKENS": "Retirer les jetons mis en jeu",
  "STAKED BALANCE": "Solde de jalonnement",
  "YOUR STAKEABLE": "Solde disponible pour le jalonnement",
  "TOTAL STAKED": "jalonnement total",
  "ESTIMATED REWARDS": "récompense attendue",
  "Rewards rate is dynamic": "Le ratio de récompense est dynamique",
  "Monthly": "mensuel",
  "Daily": "coup d`oeil",
  "CURRENT REWARDS": "récompense actuelle",
  "TOTAL REWARDS": "récompense totale",
  "CLAIM REWARDS": "demande d`indemnisation",
  "TOTAL SUPPLY": "approvisionnement total",
  "OUR PARTNERS and BACKERS": "Nos partenaires et sponsors",
  "ELONXCAT TEAM": "L`équipe ELONXCAT",
  "Founder": "fondateur",
  "Creative Director": "directeur créatif",
  "Community manger": "gestionnaire de communauté",
  "CONNECT YOUR WALLET": "Connexion au portefeuille",
  "ENTER PRESALE": "Participation avant-vente",
  "CLAIM ELONXCAT": "Facturation ELONXCAT",
  "ROAD MAP": "feuille de route",
  "DEVELOPMENT": "développement",
  "MEME DOMINATION": "Don de mèmes",
  "FREQUENTLY ASKED QUESTIONS": "Foire aux questions",
  "What is a presale": "Qu`est-ce qu`une prévente ?",
  "What is ELONXCAT": "Qu`est-ce qu`ELONXCAT ?",
  "How do I participate in the presale": "Comment participer à la prévente ?",
  "When do I receive my presale tokens": "Quand vais-je recevoir mes jetons de prévente ?",
  "What is ELONXCAT staking": "Qu’est-ce que le jalonnement ELONXCAT ?",
  "How do I stake my tokens": "Comment miser mes jetons ?",
  "I staked my tokens during presale": "J`ai misé des jetons lors de la prévente, quand puis-je les réclamer ?",
  "STAY CONNECTED": "rester connecté",
  "PRIVACY POLICY": "politique de confidentialité",
  "HOW TO BUY": "Comment acheter",
  "TERMS and CONDITIONS": "Conditions d`utilisation",
  "PRESALE ALLOCATION": "Allocation prévente",
  "AIRDROP": "largage",
  "PROJECTFUNDS": "financement de projets",
  "LIQUIDITY": "liquidité",
  "MARKETING": "commercialisation",
  "EXCHANGES": "échange",
  "ECOSYSTEM FUNDS": "fonds écosystémiques",
  "EXPANSION": "expansion",

  "texts": {
    "txt_1": "Notre propre technologie appelée X prend en charge cette multichaîne. X prend en charge la fonctionnalité de migration de chaîne, permettant aux détenteurs d`ELONXCAT de se déplacer librement entre les principales chaînes de crypto-monnaie.",
    "txt_2": "ELONXCAT est un jeton mème construit sur un réseau multichaîne couvrant la chaîne BNB, Ethereum, Polygon, Solana et Tron.",
    "txt_3": "ELONXCAT s`efforce d`unir la communauté des mèmes de crypto-monnaie et de devenir un pionnier des utilitaires multi-chaînes.",
    "txt_4": "Notre objectif est de faire briller notre nom sur chaque DEX, chaque CEX et partout ailleurs.",
    "txt_5": "Utiliser",
    "txt_6": "X continuera d`étendre ces multichaînes à l`avenir.",
  },
  "PRESALE TIERS": "étape de prévente",

  "tiers": {
    "tier_txt_1": "Le prix de prévente initial est de 0,0000009 USD par siège, qui passera à Tier2 après 20 jours.",
    "tier_txt_2": "Le prix de ce niveau est fixé à 0,000001 USD à partir de la prévente, qui passera à Tier3 après 20 jours.",
    "tier_txt_3": "Le prix de ce niveau est fixé à 0,000005 USD à partir de la prévente, qui passera au prix final après 20 jours.",
    "tier_txt_4": "Le prix de prévente de ce niveau est de 0,000009 USD, qui se terminera avec la fin de la prévente ELONXCAT à ce prix.",
    "tier_txt_5": "ELONXCAT vise à être le meilleur DEX sur BSC avec des fonctionnalités telles que les échanges de jetons, les pools de liquidités et l`agriculture de rendement.",
    "tier_txt_6": "ELONXCAT Quest combine jeu et crypto-monnaie, vous donnant la possibilité de gagner des jetons Lope grâce à des quêtes et des énigmes dans le monde de la blockchain.",
    "tier_txt_7": "Le projet ELONXCAT NFT combine l`art numérique avec les memecoins ELONXCAT pour fournir des objets de collection uniques et riches en utilités afin de renforcer l`engagement communautaire.",
    "tier_txt_8": "ELONXCAT combine finance et créativité dans une plateforme conviviale en intégrant l`IA pour le chat, les conseils de trading, la création de mèmes, et bien plus encore.",
    "tier_txt_9": "ELONXCAT Quest est un jeu blockchain au sein de l`écosystème ELONXCAT Coin, combinant jeu et crypto-monnaie dans un monde numérique inspiré de la mascotte du chat. Avec des quêtes, des énigmes et des défis PVP/PVE, les joueurs peuvent gagner des jetons Lope grâce à l`aventure, à la stratégie et à l`interaction sociale. Le jeu vise à présenter aux utilisateurs la blockchain et à créer une communauté grâce à des fonctionnalités multijoueurs.",
    "tier_txt_10": "ELONXCAT Coin NFT Marketplace possède une collection unique avec des fonctionnalités spéciales et uniques.",
    "tier_txt_11": "Les ELONXCAT Coin NFT seront répertoriés avec le projet sur tous les marchés mondiaux ainsi que sur le propre marché du projet.",
    "tier_txt_12": "Le marché comprend un vaste programme de largage qui sera activé lors de la prévente de la boîte NFT et du jeton.",
    "tier_txt_13": "Créez des blogs optimisés pour le référencement, des e-mails de vente et bien plus encore... Gagnez du temps et obtenez d`excellents résultats. Enfin, il existe un outil de création vraiment utilisable.",
    "tier_txt_14": "Visualisez ce dont vous rêvez. Créez des images avec du texte. Créez instantanément des images époustouflantes pour votre innovation artistique avec notre générateur d`images IA.",
    "tier_txt_15": "Sélectionnez simplement votre assistant et discutez avec un chatbot convivial pour générer des idées, améliorer votre contenu et parfois même faire rire.",
    "tier_txt_16": "Prêt à écrire du code à la vitesse de l`éclair ? Codez facilement avec AI Code Generator : fournit des solutions instantanées à vos besoins de programmation.",
    "tier_txt_17": "ELONXCAT Swap vise à être la principale plateforme d`échange décentralisée (DEX) sur Binance Smart Chain (BSC).",
    "tier_txt_18": "ELONXCAT Swap propose des échanges de jetons transparents, fournit des pools de liquidité et introduit des fonctionnalités innovantes telles que l`agriculture de rendement et le jalonnement pour améliorer la liquidité, garantir un commerce équitable et promouvoir la croissance globale de la communauté ELONXCAT.",
    "tier_txt_19": "La distribution des récompenses des jetons ELONXCAT se fera sur deux ans au taux de 708,72 $ELONXCAT par bloc Ethereum, et pourra être réclamée une fois la réclamation activée.",
    "tier_txt_20": "Connectez votre portefeuille en quelques secondes à l`aide de Metamask ou Trust Wallet.",
    "tier_txt_21": "Décidez du montant de $ELONXCAT que vous souhaitez acheter.  Vous pouvez acheter avec USDT ainsi que BNB, ETH et USDT.",
    "tier_txt_22": "Une fois la prévente terminée, vous pouvez réclamer vos jetons $ELONXCAT via le même portefeuille et la même chaîne que vous avez utilisés pour acheter.",
    "tier_txt_23": "La naissance d`ELONMUSK &",
    "tier_txt_24": "Création d`une communauté Génération de jetons Canaux sociaux Naissance",
    "tier_txt_25": "KYC et audit Pompe marketing Début des préventes",
    "tier_txt_26": "Démarrer le marketing CoinMarketCap  Postuler Postuler pour CoinGecko Mise à jour ElonXCAT NFT Développement de jeux ElonXCAT Mise à jour du jalonnement",
    "tier_txt_27": "Première liste DEX  Mise à jour DEXTools  Mise à jour ElonXCAT AI Gravure automatique",
    "tier_txt_28": "Marketing continu Incitations communautaires Partenariats avec d`autres plateformes Nombre cible de détenteurs : 1 million+",
    "tier_txt_29": "Les préventes offrent aux investisseurs une opportunité unique d’acheter de nouveaux jetons ou crypto-monnaies avant leur lancement officiel. Cette phase d`accès anticipé nous permet de capitaliser sur le potentiel du projet dès le début.",
    "tier_txt_30": "ELONXCAT est une pièce de monnaie multi-chaînes vraiment avancée et est en fait l`ELONMUSK & Écosystème Web3 avancé : jalonnement | DEX | IA | jeux | Marché NFT.",
    "tier_txt_31": "Vous pouvez participer à la prévente sur la chaîne BNB. Connectez Best Wallet ou un autre portefeuille au widget de prévente ci-dessus et réservez des jetons $ELONXCAT avec BNB, ETH ou USDT.",
    "tier_txt_32": "Une fois votre achat en prévente terminé, vous pouvez utiliser le même portefeuille pour réclamer vos jetons avant la première inscription DEX. Les dates de facturation et d’inscription seront annoncées sur nos comptes de réseaux sociaux.",
    "tier_txt_33": "Le jalonnement consiste à envoyer des jetons à un contrat intelligent pour les verrouiller pendant une certaine période de temps. Cela vous permet de gagner plus de jetons $ELONXCAT au fil du temps à un rythme dynamique. Le staking ELONXCAT sera bientôt disponible sur le réseau BNB. Pour plus d’informations, cliquez sur « Staking » dans la barre de navigation.",
    "tier_txt_34": "Pour miser $ELONXCAT, visitez ici : https://ELONXCAT/en/staking Le jalonnement n`est possible qu`avec $ELONXCAT acheté sur BNB. Vous aurez besoin de $BNB dans votre portefeuille pour traiter les demandes de récompense de dé-staking et de staking.",
    "tier_txt_35": "Si vous avez misé $ELONXCAT, vous devez attendre une période d`acquisition d`au moins 14 jours avant de le réclamer. Le jalonnement n’est possible qu’avec des $BNB achetés sur Ethereum. Vous aurez besoin de $BNB dans votre portefeuille pour traiter les demandes de récompense de dé-staking et de staking.",
    "tier_txt_36": "Le jalonnement consiste à envoyer des jetons à un contrat intelligent pour les verrouiller pendant une certaine période de temps. Cela vous permet de gagner plus de jetons $ELONXCAT au fil du temps à un rythme dynamique. Le staking ELONXCAT sera bientôt disponible sur le réseau BNB. Pour plus d’informations, cliquez sur « Staking » dans la barre de navigation.",
    "tier_txt_37": "Pour miser $ELONXCAT, visitez ici : https://ELONXCAT/en/staking Le jalonnement n`est possible qu`avec $ELONXCAT acheté sur BNB. Vous aurez besoin de $BNB dans votre portefeuille pour traiter les demandes de récompense de dé-staking et de staking.",
    "tier_txt_38": "Si vous avez misé $ELONXCAT, vous devez attendre une période d`acquisition d`au moins 14 jours avant de le réclamer. Le jalonnement n’est possible qu’avec des $BNB achetés sur Ethereum. Vous aurez besoin de $BNB dans votre portefeuille pour traiter les demandes de récompense de dé-staking et de staking.",
    "tier_txt_39": "BUREAU ELONXCAT PANAMA : 43e étage, Oceania BusinessPlaza, Tour 2000, rue Punta Colon, Urbanisación Punta Pacifica, Panama City, Panama",
    "tier_txt_40": "Avertissement : ELONXCAT est une pièce de monnaie mème, sa valeur est basée uniquement sur le capital culturel universel des mèmes ELONXCAT. La valeur d`une crypto-monnaie peut augmenter ou diminuer. Investissez prudemment."
  }
}