export default {
  "ABOUT": "소개",
  "TOKENOMICS": "토크노믹스",
  "STAKING": "스테이킹",
  "HOW TO BUY": "구매 방법",
  "ROADMAP": "로드맵",
  "FAQ": "자주 묻는 질문",
  "BUY NOW": "지금 구매",
  "ELONXCAT is the real": "ELONXCAT은 진짜입니다",
  "ELONMUSK": "엘론 머스크",
  "SpaceX and X": "스페이스X 및 X",
  "meme project": "밈 프로젝트",
  "Advanced Web3 Ecosystem": "고급 Web3 생태계",
  "Potential of project": "프로젝트의 잠재력",
  "Market cap Target": "목표 시가총액",
  "Investors": "투자자들",
  "WHITE PAPER": "백서",
  "AUDIT": "감사",
  "Presale will start soon. Join the Presale": "사전 판매가 곧 시작됩니다. 사전 판매에 참여하세요",
  "TOKEN DISTRIBUTION DATE": "토큰 배포일",
  "Raised": "모금액",
  "ELONXCAT sold": "ELONXCAT 판매량",
  "BUY": "구매",
  "MULTI BRIDGE UTILITY": "다중 브리지 유틸리티",
  "Total Supply": "총 공급량",
  "ELONXCAT ACHIEVEMENTS": "ELONXCAT 성과",
  "Ecosystem Percentage": "생태계 비율",
  "ELONXCAT QUEST GAME": "ELONXCAT 퀘스트 게임",
  "ELONXCAT GAME PORTAL SOON": "곧 ELONXCAT 게임 포탈 출시",
  "NFT MARKET PLACE": "NFT 마켓플레이스",
  "Unique collections": "고유한 컬렉션",
  "Launch at the same time as the project": "프로젝트와 동시에 출시",
  "NFT Box and Airdrop": "NFT 박스와 에어드랍",
  "DISCOVER NFT": "NFT 탐색",
  "AI-Powerde Content": "AI 기반 콘텐츠",
  "AI Image Generator": "AI 이미지 생성기",
  "AI Chat Bot": "AI 채팅 봇",
  "AI Code": "AI 코드",
  "COIN SWAP": "코인 스왑",
  "ELONXCAT ALLOCAT STASTISTICS": "ELONXCAT 할당 통계",
  "ELONXCAT STAKING SERVICE IS COMING SOON": "ELONXCAT 스테이킹 서비스 곧 출시 예정",
  "WELCOME TO ELONXCAT STAKING": "ELONXCAT 스테이킹에 오신 것을 환영합니다",
  "WITHDRAW STAKED TOKENS": "스테이킹된 토큰 인출",
  "STAKED BALANCE": "스테이킹 잔액",
  "YOUR STAKEABLE": "스테이킹 가능한 잔액",
  "TOTAL STAKED": "총 스테이킹",
  "ESTIMATED REWARDS": "예상 보상",
  "Rewards rate is dynamic": "보상 비율은 동적입니다",
  "Monthly": "월별",
  "Daily": "일별",
  "CURRENT REWARDS": "현재 보상",
  "TOTAL REWARDS": "총 보상",
  "CLAIM REWARDS": "보상 청구",
  "OUR PARTNERS and BACKERS": "우리의 파트너 및 후원자",
  "ELONXCAT TEAM": "ELONXCAT 팀",
  "Founder": "창립자",
  "Creative Director": "크리에이티브 디렉터",
  "Community manger": "커뮤니티 매니저",
  "CONNECT YOUR WALLET": "지갑 연결",
  "ENTER PRESALE": "사전 판매 참여",
  "CLAIM ELONXCAT": "ELONXCAT 청구",
  "ROAD MAP": "로드맵",
  "DEVELOPMENT": "개발",
  "MEME DOMINATION": "밈 도네이션",
  "FREQUENTLY ASKED QUESTIONS": "자주 묻는 질문",
  "What is a presale": "사전 판매란 무엇인가요?",
  "What is ELONXCAT": "ELONXCAT이란 무엇인가요?",
  "How do I participate in the presale": "사전 판매에 어떻게 참여하나요?",
  "When do I receive my presale tokens": "사전 판매 토큰은 언제 받을 수 있나요?",
  "What is ELONXCAT staking": "ELONXCAT 스테이킹이란 무엇인가요?",
  "How do I stake my tokens": "토큰을 어떻게 스테이킹하나요?",
  "I staked my tokens during presale": "사전 판매 중에 토큰을 스테이킹했는데, 언제 청구할 수 있나요?",
  "STAY CONNECTED": "연결 유지",
  "PRIVACY POLICY": "개인정보 보호정책",
  "HOW TO BUY": "구매 방법",
  "ABOUT": "소개",
  "TERMS and CONDITIONS": "이용 약관",
  "ROADMAP": "로드맵",
  "PRESALE ALLOCATION": "사전 판매 할당",
  "AIRDROP": "에어드랍",
  "PROJECTFUNDS": "프로젝트 자금",
  "LIQUIDITY": "유동성",
  "MARKETING": "마케팅",
  "EXCHANGES": "거래소",
  "ECOSYSTEM FUNDS": "생태계 자금",
  "EXPANSION": "확장",

  "texts": {
    "txt_1": "X라는 자체 기술이 이 멀티체인을 지원합니다. X는 체인 마이그레이션 기능을 지원하여 ELONXCAT 보유자가 최고의 암호화폐 체인을 자유롭게 이동할 수 있게 합니다.",
    "txt_2": "ELONXCAT은 BNB 체인, 이더리움, 폴리곤, 솔라나, 트론을 아우르는 멀티체인 네트워크에 구축된 밈 토큰입니다.",
    "txt_3": "ELONXCAT은 암호화폐 밈 커뮤니티를 통합하고 멀티체인 유틸리티의 선구자가 되기 위해 노력합니다.",
    "txt_4": "우리의 목표는 모든 DEX, 모든 CEX, 그리고 모든 곳에서 우리의 이름이 빛나도록 하는 것입니다.",
    "txt_5": "X의 뛰어난 브리지 기술을 활용하여 비트코인을 제외한 가장 활발한 5개 블록체인에서 $ELONXCAT은 몇 번의 클릭만으로 가스비 걱정 없이 원활하게 멀티체인화됩니다.",
    "txt_6": "X는 앞으로도 이러한 멀티체인을 계속 확장할 것입니다.",
  },
  "PRESALE TIERS": "사전 판매 단계",

  "tiers": {
    "tier_txt_1": "초기 사전 판매 가격은 좌석당 0.0000009달러이며 20일 후 Tier2로 변경됩니다.",
    "tier_txt_2": "이 티어의 가격은 사전 판매에서 0.000001달러로 설정되며 20일 후 Tier3으로 변경됩니다.",
    "tier_txt_3": "이 티어의 가격은 사전 판매에서 0.000005달러로 설정되며 20일 후 최종 가격으로 변경됩니다.",
    "tier_txt_4": "이 티어의 사전 판매 가격은 0.000009달러이며, 이 가격으로 ELONXCAT 사전 판매가 종료됩니다.",
    "tier_txt_5": "ELONXCAT은 토큰 스왑, 유동성 풀, 수익 농사와 같은 기능을 통해 BSC 상의 최고의 DEX가 되는 것을 목표로 합니다.",
    "tier_txt_6": "ELONXCAT 퀘스트는 게임과 암호화폐를 결합하여 블록체인 세계에서 퀘스트와 퍼즐을 통해 Lope 토큰을 획득할 수 있는 기회를 제공합니다.",
    "tier_txt_7": 'ELONXCAT NFT 프로젝트는 디지털 아트를 ELONXCAT 밈코인과 결합하여 고유하고 유틸리티가 풍부한 수집품을 제공하여 커뮤니티 참여를 강화합니다.',
    "tier_txt_8": "ELONXCAT은 채팅, 거래 팁, 밈 생성 등을 위한 AI를 통합하여 금융과 창의성을 사용자 친화적인 플랫폼에서 결합합니다.",
    "tier_txt_9": "ELONXCAT 퀘스트는 ELONXCAT 코인 생태계 내의 블록체인 게임으로, 고양이 마스코트에서 영감을 받은 디지털 세계에서 게임과 암호화폐를 결합합니다. 퀘스트, 퍼즐, PVP/PVE 도전 과제를 제공하며, 플레이어는 모험, 전략, 사회적 상호작용을 통해 Lope 토큰을 획득할 수 있습니다. 이 게임은 사용자에게 블록체인을 소개하고 멀티플레이 기능을 통해 커뮤니티를 구축하는 것을 목표로 합니다.",
    "tier_txt_10": "ELONXCAT 코인 NFT 마켓플레이스는 특별하고 독특한 기능을 가진 고유한 컬렉션을 보유하고 있습니다.",
    "tier_txt_11": "ELONXCAT 코인 NFT는 프로젝트와 함께 모든 글로벌 시장뿐만 아니라 프로젝트 자체 시장에서도 상장될 예정입니다.",
    "tier_txt_12": "이 마켓에는 NFT 박스와 토큰 사전 판매 중 활성화될 대규모 에어드랍 프로그램이 포함되어 있습니다.",
    "tier_txt_13": "SEO 최적화된 블로그, 판매 이메일 등을 작성하세요... 시간을 절약하면서 탁월한 결과를 얻을 수 있습니다. 마침내 진정으로 사용할 수 있는 작성 도구가 나왔습니다.",
    "tier_txt_14": "당신이 꿈꾸는 것을 시각화하세요. 텍스트로 이미지를 생성하세요. AI 이미지 생성기를 사용하여 예술적 혁신을 위한 멋진 이미지를 즉시 만드세요.",
    "tier_txt_15": "단순히 어시스턴트를 선택하고 친근한 채팅봇과 대화하여 아이디어를 창출하고, 콘텐츠를 향상시키며, 때로는 웃음을 선사할 수도 있습니다.",
    "tier_txt_16": "빛의 속도로 코드를 작성할 준비가 되셨나요? AI 코드 생성기로 손쉽게 코딩하세요: 프로그래밍 필요에 대한 즉각적인 솔루션을 제공합니다.",
    "tier_txt_17": "ELONXCAT Swap은 바이낸스 스마트 체인(BSC) 상의 선도적인 탈중앙화 거래소(DEX) 플랫폼을 목표로 합니다.",
    "tier_txt_18": "ELONXCAT Swap은 원활한 토큰 스왑을 제공하고, 유동성 풀을 제공하며, 수익 농사와 스테이킹과 같은 혁신적인 기능을 도입하여 유동성을 강화하고 공정한 거래를 보장하며 ELONXCAT 커뮤니티의 전반적인 성장을 도모합니다.",
    "tier_txt_19": "ELONXCAT 토큰 보상의 분배는 이더리움 블록당 708.72 $ELONXCAT의 비율로 2년에 걸쳐 이루어지며, 클레임이 활성화되면 청구할 수 있습니다.",
    "tier_txt_20": "Metamask 또는 Trust Wallet을 사용하여 몇 초 안에 지갑을 연결하세요.",
    "tier_txt_21": "구매할 $ELONXCAT 수량을 결정하세요. <br /> BNB, ETH, USDT와 함께 USDT로 구매할 수 있습니다.",
    "tier_txt_22": "사전 판매가 종료되면, 구매에 사용한 동일한 지갑과 체인을 통해 $ELONXCAT 토큰을 청구할 수 있습니다.",
    "tier_txt_23": "ELONMUSK & X의 탄생",
    "tier_txt_24": "커뮤니티 구축<br /> 토큰 생성<br /> 소셜 채널<br /> 탄생",
    "tier_txt_25": "KYC 및 감사<br /> 마케팅 펌프<br /> 사전 판매 시작",
    "tier_txt_26": "마케팅 시작<br /> CoinMarketCap <br /> 신청<br /> CoinGecko 신청<br /> ElonXCAT NFT 업데이트<br /> ElonXCAT 게임 개발<br /> 스테이킹 업데이트",
    "tier_txt_27": "첫 번째 DEX 상장 <br /> DEXTools 업데이트 <br /> ElonXCAT AI 업데이트<br /> 자동 소각",
    "tier_txt_28": "지속적인 마케팅<br /> 커뮤니티 인센티브<br /> 다른 플랫폼과의 파트너십<br /> 보유자 수 100만+ 목표",
    "tier_txt_29": "사전 판매는 투자자들이 정식 출시 전에 새로운 토큰이나 암호화폐를 구매할 수 있는 특별한 기회를 제공합니다. 이 초기 액세스 단계에서는 프로젝트의 잠재력을 초기 단계부터 투자할 수 있습니다.",
    "tier_txt_30": "ELONXCAT은 진정으로 진보된 멀티체인 밈 코인이며 실제로 ELONMUSK & X 밈 프로젝트입니다. 고급 Web3 생태계: 스테이킹 | DEX | AI | 게임 | NFT 마켓.",
    "tier_txt_31": "BNB 체인에서 사전 판매에 참여할 수 있습니다. 상단의 사전 판매 위젯에 Best Wallet 또는 다른 지갑을 연결하고 BNB, ETH, USDT로 $ELONXCAT 토큰을 예약하세요.",
    "tier_txt_32": "사전 판매 구매가 완료되면, 첫 번째 DEX 상장 전에 동일한 지갑을 사용하여 토큰을 청구할 수 있습니다. 청구 및 상장 날짜는 당사의 소셜 미디어 계정에서 발표될 예정입니다.",
    "tier_txt_33": "스테이킹은 토큰을 스마트 계약에 보내 일정 기간 동안 잠금하는 행위입니다. 이를 통해 동적인 비율로 시간이 지남에 따라 더 많은 $ELONXCAT 토큰을 얻을 수 있습니다. ELONXCAT 스테이킹은 곧 BNB 네트워크에서 이용 가능해질 예정입니다. 자세한 내용은 탐색 모음에서 `스테이킹`을 클릭하세요.",
    "tier_txt_34": "$ELONXCAT을 스테이킹하려면 여기를 방문하세요: https://elonxcat/en/staking 스테이킹은 BNB에서 구매한 $ELONXCAT만 가능합니다. 스테이킹 해제 및 스테이킹 보상 청구를 처리하려면 지갑에 $BNB가 필요합니다.",
    "tier_txt_35": "$ELONXCAT을 스테이킹했다면, 청구하기 전에 최소 14일의 베스팅 기간을 기다려야 합니다. 스테이킹은 이더리움에서 구매한 $BNB만 가능합니다. 스테이킹 해제 및 스테이킹 보상 청구를 처리하려면 지갑에 $BNB가 필요합니다.",
    "tier_txt_36": "스테이킹은 토큰을 스마트 계약에 보내 일정 기간 동안 잠금하는 행위입니다. 이를 통해 동적인 비율로 시간이 지남에 따라 더 많은 $ELONXCAT 토큰을 얻을 수 있습니다. ELONXCAT 스테이킹은 곧 BNB 네트워크에서 이용 가능해질 예정입니다. 자세한 내용은 탐색 모음에서 `스테이킹`을 클릭하세요.",
    "tier_txt_37": "$ELONXCAT을 스테이킹하려면 여기를 방문하세요: https://elonxcat/en/staking 스테이킹은 BNB에서 구매한 $ELONXCAT만 가능합니다. 스테이킹 해제 및 스테이킹 보상 청구를 처리하려면 지갑에 $BNB가 필요합니다.",
    "tier_txt_38": "$ELONXCAT을 스테이킹했다면, 청구하기 전에 최소 14일의 베스팅 기간을 기다려야 합니다. 스테이킹은 이더리움에서 구매한 $BNB만 가능합니다. 스테이킹 해제 및 스테이킹 보상 청구를 처리하려면 지갑에 $BNB가 필요합니다.",
    "tier_txt_39": "ELONXCAT 사무실 파나마: 43층, 오세아니아 비즈니스<br />플라자, 타워 2000, 푼타 콜론 거리, 우르바니사시온 푼타<br /> 파시피카, 파나마 시티, 파나마",
    "tier_txt_40": "면책 조항: ELONXCAT은 밈 코인으로, 그 가치는 ELONXCAT 밈의 보편적 문화 자본에만 기초합니다.<br /> 암호화폐의 가치는 오를 수도 있고 내릴 수도 있습니다. 신중하게 투자하세요."
  },
  "send_success_message": "お支払いが正常になりました。 5分以内にElonXCatトークンが支払ったアドレスに送信されます。"
}