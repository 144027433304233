export default {
    "current_language": "Arabic",
    "ABOUT": "مقدمة",
    "TOKENOMICS": "الاقتصاد الرمزي",
    "STAKING": "عمادا",
    "HOW TO BUY": "كيفية الشراء",
    "ROADMAP": "خريطة الطريق",
    "FAQ": "الأسئلة المتداولة",
    "BUY NOW": "اشتري الآن",
    "ELONXCAT is the real": "ELOXCAT حقيقي",
    "ELONMUSK": "ايلون ماسك",
    "SpaceX and X": "سبيس اكس و",
    "meme project": "مشروع ميمي",
    "Advanced Web3 Ecosystem": "النظام البيئي Web3 المتقدم",
    "Potential of project": "إمكانات المشروع",
    "Market cap Target": "القيمة السوقية المستهدفة",
    "Investors": "المستثمرين",
    "WHITE PAPER": "كتاب أبيض",
    "AUDIT": "شكرًا",
    "Presale will start soon. Join the Presale": "يبدأ البيع المسبق قريبًا. شارك في البيع المسبق",
    "TOKEN DISTRIBUTION DATE": "تاريخ توزيع الرمز المميز",
    "Raised": "المبلغ المرفوع",
    "ELONXCAT sold": "حجم مبيعات الونكسكات",
    "BUY": "شراء",
    "MULTI BRIDGE UTILITY": "فائدة جسر متعددة",
    "Total Supply": "إجمالي العرض",
    "ELONXCAT ACHIEVEMENTS": "أداء إيلونكسكات",
    "Ecosystem Percentage": "نسبة النظام البيئي",
    "ELONXCAT QUEST GAME": "لعبة ElonxCAT كويست",
    "ELONXCAT GAME PORTAL SOON": "بوابة الألعاب ELONXCAT ستتوفر قريبًا",
    "NFT MARKET PLACE": "سوق NFT",
    "Unique collections": "مجموعة فريدة من نوعها",
    "Launch at the same time as the project": "صدر في وقت واحد مع المشروع",
    "NFT Box and Airdrop": "صندوق NFT والإسقاط الجوي",
    "DISCOVER NFT": "اكتشف NFTs",
    "AI-Powerde Content": "المحتوى القائم على الذكاء الاصطناعي",
    "AI Image Generator": "مولد الصور بالذكاء الاصطناعي",
    "AI Chat Bot": "روبوت الدردشة بالذكاء الاصطناعي",
    "AI Code": "كود الذكاء الاصطناعي",
    "COIN SWAP": "مبادلة العملة",
    "ELONXCAT ALLOCAT STASTISTICS": "إحصائيات تخصيص ELOXCAT",
    "ELONXCAT STAKING SERVICE IS COMING SOON": "خدمة التوقيع المساحي ELOXCAT ستتوفر قريبًا",
    "WELCOME TO ELONXCAT STAKING": "مرحبًا بك في ELOXCAT Scking",
    "WITHDRAW STAKED TOKENS": "سحب الرموز المميزة",
    "STAKED BALANCE": "رصيد التوقيع المساحي",
    "YOUR STAKEABLE": "الرصيد متاح للتوقيع",
    "TOTAL STAKED": "إجمالي التوقيع المساحي",
    "ESTIMATED REWARDS": "المكافأة المتوقعة",
    "Rewards rate is dynamic": "نسبة المكافأة ديناميكية",
    "Monthly": "شهريا",
    "Daily": "يلمح",
    "CURRENT REWARDS": "المكافأة الحالية",
    "TOTAL REWARDS": "المكافأة الإجمالية",
    "CLAIM REWARDS": "المطالبة بالتعويض",
    "TOTAL SUPPLY": "إجمالي العرض",
    "OUR PARTNERS and BACKERS": "شركاؤنا والجهات الراعية",
    "ELONXCAT TEAM": "فريق ايلونكس كات",
    "Founder": "مؤسس",
    "Creative Director": "المدير الإبداعي",
    "Community manger": "مدير المجتمع",
    "CONNECT YOUR WALLET": "اتصال المحفظة",
    "ENTER PRESALE": "مشاركة ما قبل البيع",
    "CLAIM ELONXCAT": "فواتير ELOXCAT",
    "ROAD MAP": "خريطة الطريق",
    "DEVELOPMENT": "تطوير",
    "MEME DOMINATION": "التبرع ميمي",
    "FREQUENTLY ASKED QUESTIONS": "الأسئلة المتداولة",
    "What is a presale": "ما هو البيع المسبق؟",
    "What is ELONXCAT": "ما هو ايلونكسكات؟",
    "How do I participate in the presale": "كيف أشارك في البيع المسبق؟",
    "When do I receive my presale tokens": "متى سأتلقى رموز ما قبل البيع الخاصة بي؟",
    "What is ELONXCAT staking": "ما هو التوقيع المساحي لـ ELONXCAT؟",
    "How do I stake my tokens": "كيف يمكنني حصة الرموز الخاصة بي؟",
    "I staked my tokens during presale": "لقد قمت بإيداع العملات خلال فترة ما قبل البيع، متى يمكنني المطالبة بها؟",
    "STAY CONNECTED": "ابق على اتصال",
    "PRIVACY POLICY": "سياسة الخصوصية",
    "TERMS and CONDITIONS": "شروط الاستخدام",
    "PRESALE ALLOCATION": "تخصيص ما قبل البيع",
    "AIRDROP": "إنزال جوي",
    "PROJECTFUNDS": "تمويل المشروع",
    "LIQUIDITY": "السيولة",
    "MARKETING": "تسويق",
    "EXCHANGES": "تبادل",
    "ECOSYSTEM FUNDS": "صناديق النظام البيئي",
    "EXPANSION": "توسع",

    "texts": {
      "txt_1": "التكنولوجيا الخاصة بنا والتي تسمى X تدعم هذه السلسلة المتعددة. يدعم X وظيفة ترحيل السلسلة، مما يسمح لحاملي ELONXCAT بالتحرك بحرية عبر سلاسل العملات المشفرة الرائدة.",
      "txt_2": "ELONXCAT عبارة عن رمز مميز مبني على شبكة متعددة السلاسل تمتد عبر سلسلة BNB وEthereum وPolygon وSolana وTron.",
      "txt_3": "تسعى ELONXCAT جاهدة إلى توحيد مجتمع meme للعملات المشفرة وتصبح رائدة في المرافق متعددة السلاسل.",
      "txt_4": "هدفنا هو أن يكون اسمنا متألقًا في كل بورصة DEX، وكل CEX، وفي كل مكان آخر.",
      "txt_5": "الاستفادة",
      "txt_6": "ستستمر X في توسيع هذه السلاسل المتعددة في المستقبل.",
    },
    "PRESALE TIERS": "مرحلة ما قبل البيع",

    "tiers": {
      "tier_txt_1": "سعر البيع المسبق الأولي هو 0.0000009 دولار أمريكي للمقعد، والذي سيتغير إلى Tier2 بعد 20 يومًا.",
      "tier_txt_2": "تم تحديد سعر هذا المستوى بـ 0.000001 دولار أمريكي من البيع المسبق، والذي سيتغير إلى Tier3 بعد 20 يومًا.",
      "tier_txt_3": "تم تحديد سعر هذا المستوى بـ 0.000005 دولار أمريكي من البيع المسبق، والذي سيتغير إلى السعر النهائي بعد 20 يومًا.",
      "tier_txt_4": "سعر البيع المسبق لهذا المستوى هو 0.000009 دولار أمريكي، والذي سينتهي مع انتهاء البيع المسبق ELONXCAT بهذا السعر.",
      "tier_txt_5": "تهدف ELONXCAT إلى أن تكون أفضل منصة DEX على BSC مع ميزات مثل مقايضات العملات الرمزية، ومجموعات السيولة، وزراعة العائدات.",
      "tier_txt_6": "تجمع ELONXCAT Quest بين الألعاب والعملات المشفرة، مما يتيح لك الفرصة لكسب رموز Lope من خلال المهام والألغاز في عالم blockchain.",
      "tier_txt_7": "يجمع مشروع ELONXCAT NFT بين الفن الرقمي وعملات ELONXCAT memecoins لتوفير مقتنيات فريدة وغنية بالمرافق لتعزيز مشاركة المجتمع.",
      "tier_txt_8": "يجمع ELONXCAT بين التمويل والإبداع في منصة سهلة الاستخدام من خلال دمج الذكاء الاصطناعي للدردشة ونصائح التداول وإنشاء الميمات والمزيد.",
      "tier_txt_9": "ELONXCAT Quest هي لعبة blockchain ضمن النظام البيئي ELONXCAT Coin، تجمع بين الألعاب والعملات المشفرة في عالم رقمي مستوحى من تميمة القط. تتميز بالمهام والألغاز وتحديات PVP/PVE، ويمكن للاعبين ربح رموز Lope من خلال المغامرة والاستراتيجية والتفاعل الاجتماعي. تهدف اللعبة إلى تعريف المستخدمين بـ blockchain وبناء مجتمع من خلال ميزات متعددة اللاعبين.",
      "tier_txt_10": "يحتوي ELONXCAT Coin NFT Marketplace على مجموعة فريدة من نوعها ذات ميزات خاصة وفريدة من نوعها.",
      "tier_txt_11": "سيتم إدراج ELOXCAT Coin NFTs جنبًا إلى جنب مع المشروع في جميع الأسواق العالمية وكذلك في السوق الخاصة بالمشروع.",
      "tier_txt_12": "يشتمل السوق على برنامج Airdrop كبير سيتم تفعيله خلال مرحلة ما قبل البيع لصندوق NFT والرمز المميز.",
      "tier_txt_13": "أنشئ مدونات ورسائل بريد إلكتروني للمبيعات محسنة لتحسين محركات البحث والمزيد... وفر الوقت واحصل على نتائج رائعة. وأخيرًا، هناك أداة تأليف قابلة للاستخدام حقًا.",
      "tier_txt_14": "تصور ما تحلم به. إنشاء الصور مع النص. أنشئ على الفور صورًا مذهلة لابتكارك الفني باستخدام منشئ الصور المدعم بالذكاء الاصطناعي.",
      "tier_txt_15": "ما عليك سوى اختيار مساعدك والدردشة مع برنامج الدردشة الآلي الودود لتوليد الأفكار وتحسين المحتوى الخاص بك وفي بعض الأحيان تقديم الضحك.",
      "tier_txt_16": "هل أنت مستعد لكتابة التعليمات البرمجية بسرعة البرق؟ قم بالبرمجة بسهولة باستخدام AI Code Generator: يوفر حلولاً فورية لاحتياجات البرمجة الخاصة بك.",
      "tier_txt_17": "تهدف ELONXCAT Swap إلى أن تكون منصة التبادل اللامركزي الرائدة (DEX) على Binance Smart Chain (BSC).",
      "tier_txt_18": "يوفر ELONXCAT Swap مقايضات رمزية سلسة، ويوفر مجمعات سيولة، ويقدم ميزات مبتكرة مثل زراعة العائدات والستاكينغ لتعزيز السيولة، وضمان التداول العادل، وتعزيز النمو الشامل لمجتمع ELONXCAT.",
      "tier_txt_19": "سيتم توزيع مكافآت توكن ELONXCAT على مدار عامين بمعدل 708.72 دولارًا أمريكيًا ELONXCAT لكل كتلة إيثريوم، ويمكن المطالبة بها بمجرد تنشيط المطالبة.",
      "tier_txt_20": "قم بتوصيل محفظتك في ثوانٍ باستخدام Metamask أو Trust Wallet.",
      "tier_txt_21": "قرر المبلغ الذي ترغب في شراءه من ELOXCAT. يمكنك الشراء باستخدام USDT بالإضافة إلى BNB وETH وUSDT.",
      "tier_txt_22": "بمجرد انتهاء فترة البيع المسبق، يمكنك المطالبة بعملات $ELONXCAT المميزة الخاصة بك من خلال نفس المحفظة والسلسلة التي استخدمتها للشراء.",
      "tier_txt_23": "ولادة إيلونمسك &",
      "tier_txt_24": "بناء المجتمع إنشاء الرمز المميز القنوات الاجتماعية الميلاد",
      "tier_txt_25": "اعرف عميلك والتدقيق مضخة التسويق يبدأ البيع المسبق",
      "tier_txt_26": "ابدأ التسويق CoinMarketCap تقدم بطلب تقدم بطلب للحصول على CoinGecko تحديث ElonXCAT NFT تطوير لعبة ElonXCAT تحديث التوقيع المساحي",
      "tier_txt_27": "قائمة DEX الأولى تحديث DEXTools تحديث ElonXCAT AI النسخ التلقائي",
      "tier_txt_28": "تسويق مستمر حوافز مجتمعية شراكات مع منصات أخرى العدد المستهدف من المالكين: 1 مليون+",
      "tier_txt_29": "توفر عمليات ما قبل البيع فرصة فريدة للمستثمرين لشراء الرموز المميزة أو العملات المشفرة الجديدة قبل إطلاقها رسميًا. تسمح لنا مرحلة الوصول المبكر هذه بالاستفادة من إمكانات المشروع منذ البداية.",
      "tier_txt_30": "ELONXCAT هي عملة ميمية متقدمة ومتعددة السلاسل وهي في الواقع Elonmusk & النظام البيئي Web3 المتقدم: التوقيع المساحي | التنفيذ المباشر | منظمة العفو الدولية | العاب | سوق NFT.",
      "tier_txt_31": "يمكنك المشاركة في البيع المسبق على سلسلة BNB. قم بتوصيل Best Wallet أو محفظة أخرى بأداة ما قبل البيع أعلاه واحتفظ برموز $ELONXCAT المميزة مع BNB أو ETH أو USDT.",
      "tier_txt_32": "بمجرد اكتمال عملية الشراء ما قبل البيع، يمكنك استخدام نفس المحفظة للمطالبة بالرموز المميزة الخاصة بك قبل إدراج DEX الأول. سيتم الإعلان عن مواعيد الفواتير والإدراج على حسابات وسائل التواصل الاجتماعي الخاصة بنا.",
      "tier_txt_33": "التوقيع المساحي هو إرسال الرموز المميزة إلى عقد ذكي لقفلها لفترة زمنية معينة. يتيح لك هذا كسب المزيد من رموز ELONXCAT $ بمرور الوقت بمعدل ديناميكي. ستتوفر حصة ELONXCAT قريبًا على شبكة BNB. لمزيد من المعلومات، انقر فوق \"Stake\" في شريط التنقل.",
      "tier_txt_34": "للمراهنة بـ $ELONXCAT، تفضل بزيارة هنا: https://ELONXCAT/en/stakeing لا يمكن إجراء Stake إلا مع $ELONXCAT الذي تم شراؤه على BNB. ستحتاج إلى $BNB في محفظتك لمعالجة مطالبات المكافأة وإلغاء التوقيع المساحي.",
      "tier_txt_35": "إذا قمت بإيداع مبلغ ELOXCAT بقيمة $، فيجب عليك الانتظار لفترة استحقاق لا تقل عن 14 يومًا قبل المطالبة به. لا يمكن إجراء عملية الستاكينغ إلا من خلال شراء $BNB على Ethereum. ستحتاج إلى $BNB في محفظتك لمعالجة مطالبات المكافأة وإلغاء التوقيع المساحي.",
      "tier_txt_36": "التوقيع المساحي هو إرسال الرموز المميزة إلى عقد ذكي لقفلها لفترة زمنية معينة. يتيح لك هذا كسب المزيد من رموز ELONXCAT $ بمرور الوقت بمعدل ديناميكي. ستتوفر حصة ELONXCAT قريبًا على شبكة BNB. لمزيد من المعلومات، انقر فوق \"Stake\" في شريط التنقل.",
      "tier_txt_37": "للمراهنة بـ $ELONXCAT، تفضل بزيارة هنا: https://ELONXCAT/en/stakeing لا يمكن إجراء عملية Stake إلا مع $ELONXCAT الذي تم شراؤه على BNB. سوف تحتاج إلى BNB دولار في محفظتك لمعالجة مطالبات المكافأة وإلغاء التوقيع المساحي.",
      "tier_txt_38": "إذا قمت بإيداع مبلغ ELOXCAT بقيمة $، فيجب عليك الانتظار لفترة استحقاق لا تقل عن 14 يومًا قبل المطالبة به. لا يمكن إجراء عملية الستاكينغ إلا من خلال شراء $BNB على Ethereum. سوف تحتاج إلى BNB دولار في محفظتك لمعالجة مطالبات المكافأة وإلغاء التوقيع المساحي.",
      "tier_txt_39": "مكتب ELONXCAT بنما: الطابق 43، أوقيانوسيا للأعمال بلازا، برج 2000، شارع بونتا كولون، Urbanisación Punta باسيفيكا، مدينة بنما، بنما",
      "tier_txt_40": "إخلاء المسؤولية: ELONXCAT هي عملة ميمي، وتعتمد قيمتها فقط على رأس المال الثقافي العالمي لميمات ELONXCAT. يمكن أن ترتفع قيمة العملة المشفرة أو تنخفض. استثمر بعناية."
    }
  }