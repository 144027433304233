import React from 'react';
import App from './App';
import Wrapper from './Wrapper';

const Main = () => (
    // <React.StrictMode>
    <Wrapper>
        <App />
    </Wrapper>
    // </React.StrictMode>,
)

export default Main;
